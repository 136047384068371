.moreActivities{
  display: flex;
  padding-top: 5px;
}

.allActivities{
  margin-top: 20px;
}

.allActivities h2{
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: gray;

}

.Activities{
  display: flex;
  flex-direction: row;
  place-content: center;
  margin-top: 10px;
  columns: 3;
  padding-left: 8px;
  flex-wrap: wrap;
}

@media (max-width: 500px) {
  .home{
    margin-top: 7rem;
  }
  }


@media (max-width: 580px) {
.Activities {
columns: 2;
}
}

@media (max-width: 355px) {
.Activities {
columns: 1;
}
}


.loader {
  margin-bottom: -20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

