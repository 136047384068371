.card-activity-container {
  display: inline-flex;
  padding: 1rem;
  padding-left: 0px;
  padding-right: 0px;
  width: 10.8rem;

  justify-content: center;
  -webkit-column-break-inside: avoid;
}
.card-activity-container:hover {
  cursor: pointer;
}

.card-activity-post {
  margin-top: -1.5rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 1px 2px 10px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 2px 10px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 2px 10px -7px rgba(0, 0, 0, 0.75);
  border-radius: 25px;
}

.card-activity-image {

  width: 10rem;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  object-fit: contain;
}
.card-activity-image img {
  width: 10rem;
  height: 10rem;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  
}

.card-activity-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.card-activity-info h1 {
  width: 8rem;
  height: 34px;
  margin: 0.5rem;
  font-size: 0.8rem;
  font-weight: 300px;

}

.activity-share-icon {
   z-index: 1!important; 
  margin-right: -2rem;
  margin-top: -0.5rem;

}

.activity-share-icon svg {
  /*round background*/
  background-color: rgba(108, 108, 109, 0.2);
  border-radius: 50px
  /*glow effect*/
  
}


.activity-share-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}

.activity-share-buttons svg {
  padding: 0.2rem;

}

.activity-share-title h3 {
  margin-top: 3px;
}

