header{
  width: 100%;
  padding: 5px 0;
  position: fixed;
  top: 0;
  background-color: rgba(234 237 240 / 90%);
  z-index: 5;
}

header .wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 9rem;
}


header .wrapper-small{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 6.5rem;
}


.nav-bar {
  display: flex;
  flex-direction: column;

}

.filter-bar {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
}

.logo{
  flex: 1;
  margin-right: 30px;
  cursor: pointer;
  min-width: 300px;

}

.logo h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.logo img{
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

header .search{
  width: 100%;
  background-color: white;
}

header .search input{       
  height: 30px;
}

.multiSelectContainer  {
  width: 7.4rem!important;
}

@media(min-width: 725px){

  header{
    padding: 15px 0 10px;
  }

  header .wrapper{
    flex-direction: row;
    min-height: 5.5rem;
  }

  header .wrapper-small{
    flex-direction: row;
    min-height: 5.5rem;
  }


  .logo {
    flex: 1;
  } 

  .logo h2 {
    justify-content: unset;
  }


}
@media(min-width: 900px){


.multiSelectContainer  {
  width: 100%!important;
}
}