
.activity-image img {
  border-radius: 20px;
  border: 1px;
  border-style: solid;
  border-color: #000000;
  height: 15rem;
  width: 15rem;
  margin: 10px;
}
