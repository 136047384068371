.activityDetail{
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
  margin-bottom: 20px;
}

.card {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: top;
}
.card-image {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
}
.card-image img {
  border-radius: 15px;
  height: 16rem;
}

.card-text {
  display: flex;
  flex-direction: column;
}

.share-buttons {

  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.share-buttons svg {

  padding: 3px;
}
.activity-body{
  margin-left: 15px;
  margin-right: 15px;
}
@media (min-width: 500px){
  .activityDetail{
    margin-top: 7rem;
  }

  .card {
    flex-direction: row;
    
  }
  .card-text {
    flex-grow: 1;

  }


} 
